//console.log(`------------------------------------------`);
//console.log(`Welcome to Key Life. God's Not Mad at you!`);
//console.log(`------------------------------------------`);

console.log(`%cGod's Not Mad at You!`, 'font-size: 40px; color: green');

document.addEventListener('DOMContentLoaded', () => {
    // do your setup here
    let expired = getWithExpiry("klnWelcome");

    if (expired === null) {
        let splash = document.querySelector(".splash");

        splash.classList.add('play');

        setTimeout(() => {
            splash.classList.remove('play');
            let expire = 1000 * 60 * 60 * 24; // 1 day
            //let expire = 1000 * 60; // 1 minute
            setWithExpiry("klnWelcome", `God's Not Mad at You`, expire);
        }, 3500);
    }
});


function setWithExpiry(key, value, ttl) {
    const now = new Date();

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    //let time = now.getTime() + ttl;
    //console.log(time);
    //let expiration = new Date(time);
    //console.log(`${expiration.getMonth() + 1} / ${expiration.getDate()} / ${expiration.getFullYear()} - ${expiration.getHours()} : ${expiration.getMinutes()} : ${expiration.getSeconds()}`);
    const item = {
        value: value,
        expiry: now.getTime() + ttl
    };
    localStorage.setItem(key, JSON.stringify(item));
}

function getWithExpiry(key) {
    //console.log(`Attempting to get local storage item with key: ${key}`);
    const itemStr = localStorage.getItem(key);
    //console.log(itemStr);
    // if the item doesn't exist, return null
    if (!itemStr) {
        //console.log(`Item doesn't exist`);
        return null
    }
    const item = JSON.parse(itemStr);
    //console.log(`Expiry: ${item.expiry}`);
    const now = new Date();
    //console.log(`Current Time: ${now.getTime()}`); // Should be less
    //console.log(now.getTime > item.expiry); // Should return true or false
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key);
        //console.log(`Removed Key: ${key}`);
        return null;
    }
    return item.value;
}